import React from "react";
import { Container, Text, Button, Image } from "@atoms";
import { Carousel } from "@molecules";
import clsx from "clsx";

const Item = ({ image, heading, descriptor, link }) => {
  return (
    <>
      <Image
        ixParams={{ fit: "crop" }}
        className="rounded-2xl"
        image={image}
        aspectRatio={[3.5, 2]}
      />
      <Text variant="h6" className="mt-6 text-center text-purple">
        {heading}
      </Text>
      <Text variant="sm" className="my-3 text-center">
        {descriptor}
      </Text>
      <div className="relative z-10 flex w-full shrink-0 flex-wrap justify-center gap-4 px-4">
        {link?.url && (
          <Button to={link.url} size="sm" color="purple">
            <Text>{link.text}</Text>
          </Button>
        )}
      </div>
    </>
  );
};

const SmallImageGrid = ({ grid }) => {
  return (
    <div className="my-10 sm:my-20">
      <Container smallerPadding>
        <ul
          className={clsx(
            "relative z-10 mx-auto my-10 hidden max-w-5xl grid-cols-1 gap-8 px-10 md:grid",
            {
              "md:grid-cols-2": grid?.length === 2,
              "md:grid-cols-3": grid?.length !== 2,
            }
          )}
        >
          {grid.map(r => (
            <li key={r.uid}>
              <Item {...r} />
            </li>
          ))}
        </ul>
        <div className="block md:hidden">
          <Carousel indicators>
            {grid.map(r => (
              <div key={r.uid}>
                <Item {...r} />
              </div>
            ))}
          </Carousel>
        </div>
      </Container>
    </div>
  );
};

export default SmallImageGrid;
